import 'bootstrap/dist/css/bootstrap.css';

import './css/MemberTile.css';

import React, {useState, useEffect} from 'react';

function MemberTile(props) {

    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() =>{
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    },[]);
      
    const isMobile = width <= 600;

    if(isMobile){
        return(<div className='panel'>
                    
                    <h3 className='name' style = {{textAlign: "center"}}>{props.title}</h3>
                    
                    <div className='info'>
                        
                        <img alt="" src = {props.image}/>
                        <p className='description'>{props.description}</p>
                    </div>
                </div>  
        )
    } else {
        if(props.mirror){
            return (
                <div className='panel'>
                    
                    <h3 className='name' style = {{marginLeft: "20px", textAlign: "left"}}>{props.title}</h3>
                    
                    <div className='info'>
                        
                        <img className="portrait" alt="" style = {{marginRight: "20px"}} src = {props.image}/>
                        <p className='description'>{props.description}</p>
                    </div>
                </div>  
            )
        } else {
            return (
                <div className='panel'>
                    
                    <h3 className='name' style = {{marginRight: "20px", textAlign: "right"}}>{props.title}</h3>
                    
                    
                    <div className='info'>
                        <p className='description'>{props.description}</p>
                        <img className="portrait" style = {{marginLeft: "20px"}} src = {props.image} alt=""/>
                    </div>
                </div>  
            )
        }
    }
    
    
}

export default MemberTile;