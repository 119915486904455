import data from '../data/slides.json'

import ReferenceTile from '../components/ReferenceTile'

function ReferencesPage() {
    return (
        <>
             <h1 className="title">References</h1>

             <div>

                <ReferenceTile title={data.dundee.title} reference={data.dundee.reference}/>

                <ReferenceTile title={data.beekeeping.title} reference={data.beekeeping.reference}/>

                <ReferenceTile title={data.fantasmagoria.title} reference={data.fantasmagoria.reference}/>

                

                <ReferenceTile title={"Science"} reference={"Wagenaar, W., Duller, A. and Wagenaar-Fischer, M., 2014. Dutch perspectives.\n\nwww.magiclanternsociety.org. 2022. [online] Available at: <http://magiclanternsociety.org/> [Accessed 16 July 2022]."}/>

                <ReferenceTile title={"3D Model"} reference={'This work is based on "Llanterna màgica, Museu del Cinema" (https://sketchfab.com/3d-models/llanterna-magica-museu-del-cinema-7c0200a31c474a309698102d0b5b80b8) by Giravolt (https://sketchfab.com/giravolt) licensed under CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)'}/>


             </div>
        </>
    )
}

export default ReferencesPage;