import 'bootstrap/dist/css/bootstrap.css';

import MyNavbar from './components/ExtraNavbar.js';
import HomePage from './pages/HomePage.js';
import StoryPage from './pages/StoryPage.js';
import AboutPage from './pages/AboutPage.js';
import SciencePage from './pages/SciencePage.js';
import TeamPage from './pages/TeamPage.js';
import ShowPage from './pages/ShowPage.js';
import StoriesPage from './pages/StoriesPage.js';
import ReferencesPage from './pages/ReferencesPage.js';


import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

function App() {
    return (
        <Router>
            <MyNavbar/>
            <div className="App">
                
                <div className="content">
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/story/:id" element={<StoryPage/>}/>
                        <Route path="/team" element={<TeamPage/>}/>
                        <Route path="/show" element={<ShowPage/>}/>
                        <Route path="/science" element={<SciencePage/>}/>
                        <Route path="/about" element={<AboutPage/>}/>
                        <Route path="/stories" element={<StoriesPage/>}/>
                        <Route path="/references" element={<ReferencesPage/>}/>
                        
                    </Routes>
                   
                </div>
            </div>
        </Router>
    )
}

export default App;