import 'bootstrap/dist/css/bootstrap.css'
import '../css/ShowPage.css'

import slide1 from '../assets/backgrounds/slide1_rs.png'
import slide2 from '../assets/backgrounds/slide2_rs.png'
import slide3 from '../assets/backgrounds/slide3_rs.png'
import slide4 from '../assets/backgrounds/slide4_rs.png'

import React, { useState, useEffect } from 'react';

import Slide from '../components/Slide'


function HomePage() {

    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() =>{
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    },[]);

    const isMobile = width <= 600;

    return (
        <>
            <h1 className="title">Magic Lantern</h1>
            <div className="something">
                    <div>
                        <Slide text='Science' route="/science" image={slide2}></Slide>
                    </div>
                    <div>
                        <Slide text='Team' route="/team" image={slide1}></Slide>
                    </div>

                    <div>
                        <Slide text='Stories' route="/stories" image={slide3}></Slide>
                    </div>

                    <div>
                        <Slide text='About' route="/about" image={slide4}></Slide>
                    </div>
                    {isMobile && <div className="buff"></div>}
            </div> 
            
        </>
        
    )
}

export default HomePage;

/*<YoutubeEmbed embedId='dQw4w9WgXcQ'/>
<h1 className="title">Show</h1>*/
