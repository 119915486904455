import 'bootstrap/dist/css/bootstrap.css';

import './css/MemberTile.css';


function ReferenceTile(props) {
    return (
        <div className='panel'>
            <h2 className="name" style = {{marginLeft: "3%", textAlign: "left"}}>{props.title}</h2>

            <div style = {{ color: "white",maxHeight: "30rem",overflowY: 'scroll', overflowWrap: 'break-word', scrollbarGutter: 'stable',scrollbarColor: '#6969dd #e0e0e0',scrollbarWidth: 'thin'}}>
                {props.reference.split('\n\n').map(paragraph =>
                    <p className="description">
                        {console.log(paragraph)}
                        {paragraph.split('\n').reduce((total, line) => [total, <br />, line])}
                    </p>
                )}
            </div>
        </div>  
    )
}

export default ReferenceTile;