import 'bootstrap/dist/css/bootstrap.css'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import logo from '../assets/logoML.png'

function ExtraNavbar() {
    return (
        <div>
            <Navbar bg="dark" variant="dark" sticky="top" expand="sm" collapseOnSelect>
                <Navbar.Brand>
                    <a href="/" style={{textDecoration: 'none'}}>
                        <img className="logo" src = {logo} alt=""/>
                        
                    </a>
                    Magic Lantern
                </Navbar.Brand>

                <Navbar.Toggle className="coloring" />
                <Navbar.Collapse>
                    <Nav>
                        <NavDropdown title="Stories">
                                <NavDropdown.Item href="/story/1">Dundee</NavDropdown.Item>
                                <NavDropdown.Item href="/story/2">Beekeeping</NavDropdown.Item>
                                <NavDropdown.Item href="/story/3">Ghost Stories</NavDropdown.Item>
                        </NavDropdown>
                            <Nav.Link href = "/science">Science</Nav.Link>
                            <Nav.Link href = "/team">Team</Nav.Link>
                            <Nav.Link href = "/show">Show</Nav.Link>
                            <Nav.Link href = "/references">References</Nav.Link>
                            <Nav.Link href = "/about">About</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>       
         </div>
    )
}
export default ExtraNavbar;