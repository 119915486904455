import Lantern from '../components/Lantern.js'
import data from '../data/slides.json'


import React, {useState, useEffect} from 'react';

function SciencePage() {

    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() =>{
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    },[]);

    const isMobile = width <= 600;

    if(isMobile){
        return(
            <>
                <h2 className="title">Science</h2>
                
                <div style={container}>
                    <div style={mobile_box}>
                        <div style={mobile_slide_container}>
                            <Lantern/>
                        </div>
                        <div style={mobile_story_container}>
                            <p style={story}>{data.science}</p>
                        </div>  
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <h1 className="title">Science</h1>
                <div style={container}>
                    <div style={box}>
                        <div style={story_container}>
                            <p style={story}>{data.science}</p>
                        </div>
                        <div style={slide_container}>
                            <Lantern/>
                        </div>  
                    </div>
                </div>
            </>  
        )
    } 
}

const slide_container = {
    width: '60%',
    height: 'min-content',
    background: 'black',
    marginLeft: '1%'
}

const mobile_slide_container = {
    width: '100%',
    height: 'min-content',
    background: 'black',
   
    
    
}

const story_container = {
    maxWidth: '40%',
    height: 'fit-content',
    maxHeight: '100%',
    color: 'white',
    overflowY: 'scroll',
    scrollbarGutter: 'stable',
    scrollbarColor: '#6969dd #e0e0e0',
    scrollbarWidth: 'thin',
    background: 'rgba(128, 128, 128, 0.9)'
}

const mobile_story_container = {
    maxWidth: '100%',
    height: 'fit-content',
    maxHeight: '100%',
    marginTop: '2%',
    color: 'white',
    overflowY: 'scroll',
    scrollbarGutter: 'stable',
    scrollbarColor: '#6969dd #e0e0e0',
    scrollbarWidth: 'thin',
    background: 'rgba(128, 128, 128, 0.9)'
}

const container = {
    display: 'flex',
    marginLeft: '5%',
    marginRight: '5%',
    paddingBottom: '5%',
    width: '90%',
    height: 'fit-content',
    maxHeight: '70vh',
}

const box = {
    display: 'flex',
    marginLeft: '0.5%',
    marginRight: '0.5%',
}

const mobile_box = {
   
    marginLeft: '0.5%',
    marginRight: '0.5%',
}

const story = {
    marginLeft: '3%',
    marginTop: '2%',
    marginRight: '3%',
    marginBottom: '2%',
    lineHeight: '1.5',
    fontWeight: 'bold',
}

export default SciencePage;