import React from "react";

import YoutubeEmbed from "../components/YoutubeEmbed";

function ShowPage() {
    return (
        <YoutubeEmbed embedId="1F37M4wKt0U" />
    )
}

export default ShowPage;