import React from 'react';

function Tag(props) {
    return (
        <div style = {tag}>
            <h1>{props.text}</h1>
        </div>
    )
}

const tag = {
    color: 'white',
    height: 'fit-content',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    margingTop: '1%',
    background: 'rgba(128, 128, 128, 0.9)',
    textAlign: 'center'
}

export default Tag;

/*
const transitions = useTransition(props.visible, {
        from: {opacity: 0, marginTop: 0},
        enter: {opacity: 1, marginTop: 20},
        leave: {opacity: 0, marginTop: 0},
    });

    return (
        <div>
            {transitions((style, item) => 
                item ? 
                <animated.div style = {style}>
                        <div style = {tag}>
                            <img src={props.picture} alt=""/>
                        </div>
                </animated.div> 
                : '')}    
        </div>
    )*/