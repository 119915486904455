import 'bootstrap/dist/css/bootstrap.css'
import '../css/HomePage.css'
import MemberTile from '../components/MemberTile.js';

import kirsty_image from '../assets/kirsty_rs.png'
import anushrut_image from '../assets/anushrut_rs.png'
import elizabeth_image from '../assets/elizabeth_rs.png'
import marat_image from '../assets/marat_rs.png'
import charlotte_image from '../assets/charlotte_rs.png'
import andrea_image from '../assets/andrea_rs.png'
import di_image from '../assets/di_rs.png'



const description_kirsty = "Kirsty Sinclair Dootson is a lecturer in Film Studies at the University of St Andrews. She specialises in the material and technical history of visual media from the nineteenth-century to the present.";
const description_anushrut = "Anushrut Ramakrishnan Agrwaal is a PhD student in Film Studies at the University of St Andrews. He studies the educational use of visual media in the nineteenth and twentieth century. ";
const description_elizabeth = "Elizabeth Hayslett is a fourth-year undergraduate student at the University of St. Andrews. She studies Art History and English, with the hopes to pursue museum studies and art conservation. Her section of this project was the research and writing of the Dundee Story.";
const description_marat = "Marat Davudov is a third-year undergraduate student at the University of St. Andrews, studying Computer Science. His section of this project was designing this website."
const description_charlotte = "Charlotte Welch is a third-year undergraduate student at the University of St. Andrews. She studies Arabic and Social Anthropology and would like to work in the heritage sector. Her section of this project was researching and writing the Beekeeping Story."
const description_andrea = "Andrea Cuniolo is a fourth-year undergraduate student, studying Modern History and Philosophy at St. Andrews. His field of research for the project was ghost stories of St. Andrews. In the future he hopes to work in academia as a researcher.";
const description_di = "Di Liu is a third-year undergraduate student at the University of St. Andrews. She studies Math and Physics and is proud of being a nerd. Her section of this project was figuring out the science behind the magic lantern and she encourages everyone to search up Petzvel lens because it’s really cool!";

function TeamPage() {

    return (
        <div className="page">
           
            <h1 className="title">Our Team</h1>

            <MemberTile title="Elizabeth Hayslett" description={description_elizabeth} image={elizabeth_image} mirror={false}/>
            <MemberTile title={"Marat Davudov"} description={description_marat} image={marat_image} mirror={true}/>
            <MemberTile title={"Charlotte Welch"} description={description_charlotte} image={charlotte_image} mirror={false}/>
            <MemberTile title={"Andrea Cuniolo"} description={description_andrea} image={andrea_image} mirror={true}/>
            <MemberTile title={"Di Liu"} description={description_di} image={di_image} mirror={false}/>
            <MemberTile title="Kirsty Sinclair Dootson" description={description_kirsty} image={kirsty_image} mirror={true}/>
            <MemberTile title="Anushrut Ramakrishnan Agrwaal" description={description_anushrut} image={anushrut_image} mirror={false}/>
            <div style={{height: "50px"}}>

            </div>
        </div>  
        
    )
}

/*
*/

export default TeamPage;