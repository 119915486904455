import 'bootstrap/dist/css/bootstrap.css'
import '../css/HomePage.css'

import data from '../data/slides.json'

import React, {useState, useEffect} from 'react';

import { useParams } from 'react-router';

function StoryPage() {
    const id = useParams().id;

    const loadSlideData = () => {
        switch(id){
            case '1':
                return data.dundee;
            case '2':
                return data.beekeeping;
            case '3':
                return data.fantasmagoria;
            case '4':
                return data.dundee;
            default:
                console.log("NOT FOUND");
                return null;
        }
    }

    const slide = loadSlideData();
    
    const [counter, setCounter] = useState(0);
    
    const increment = () => {
        setCounter(prev => prev + 1);
    };

    const decrement = () => {
        setCounter(prev => prev - 1);
    };

    
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };


    useEffect(() =>{
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    },[]);
      
    const isMobile = width <= 600;
    
    if(isMobile){
        return (
            <>
                <h2 className="title">{slide.title} </h2>
                
                <div style={mobile_container}>
                    
                
                    <div style={mobile_box}>

                        <div>
                            <div style={slide_container}>
                                <img style={img} src={slide.slides[counter].image} alt=""/>
                            </div> 

                            <div style={controls}>
                        

                                {counter === 0 ? 
                                    <div style={button}>
                                        <button style={btn_inactive}>{"Back"}</button>
                                    </div>
                                    :
                                    <div style={button}>
                                        <button style={btn} onClick={decrement}>{"Back"}</button>
                                    </div>
                                }

                                {counter === slide.slides.length - 1 ?  
                                    <div style={button}>
                                        <button style={btn_inactive}>{"Next"}</button>
                                    </div>
                                    :
                                    <div style={button}>
                                        <button style={btn} onClick={increment}>{"Next"}</button>
                                    </div>       
                                }
                            </div>

                        </div>
                        
                        
                        <div style={mobile_story_container}>
                            <p style={story}>{slide.slides[counter].text}</p>
                        </div>
                    </div>

                    
                
                    <div className="buff"></div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <h1 className="title">{slide.title} </h1>
    
                <div style={container}>
                    
                    <div style={box}>
                        
                        <div style={story_container}>
                        {slide.slides[counter].text.split('\n\n').map(paragraph =>
                            <p className="description">
                                {console.log(paragraph)}
                                {paragraph.split('\n').reduce((total, line) => [total, <br />, line])}
                            </p>
                        )}
                            
                        </div>

                        <div>
                            <div style={slide_container}>
                                <img style={img} src={slide.slides[counter].image} alt=""/>
                                
                            </div> 
                            <div style={controls}>
                                {counter === 0 ? 
                                    <div style={button}>
                                        <button style={btn_inactive}>{"Back"}</button>
                                    </div>
                                    :
                                    <div style={button}>
                                        <button style={btn} onClick={decrement}>{"Back"}</button>
                                    </div>
                                }

                                {counter === slide.slides.length - 1 ?  
                                    <div style={button}>
                                        <button style={btn_inactive}>{"Next"}</button>
                                    </div>
                                    :
                                    <div style={button}>
                                        <button style={btn} onClick={increment}>{"Next"}</button>
                                    </div>       
                                }
                            </div>
                        </div>
                        
                    </div>
                
                    
                </div>
            </>  
        )
    }
}

export default StoryPage;

const button = {
    height: '5%',
    
    
}

const controls = {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '1rem'
    
}

const btn = {
    borderRadius: '5px',
    fontWeight: 'bold',
    border: 'none',
    backgroundColor: 'orange',
}

const btn_inactive = {
    borderRadius: '5px',
    fontWeight: 'bold',
    border: 'none',
    opacity: '0.5'
}

const img = {
    maxWidth: '96%',
    maxHeight: '96%',
    marginLeft: '2%',
    marginTop: '2%',
    marginRight: '2%',
    marginBottom: '2%',
    borderRadius: '10px'
}

const slide_container = {
    
    width: 'fit-content',
    height: 'min-content',
    background: 'black',
    marginLeft: '1%'
}

const story_container = {
    maxWidth: '40%',
    height: 'fit-content',
    maxHeight: '100%',
    color: 'white',
    overflowY: 'scroll',
    scrollbarGutter: 'stable',
    scrollbarColor: '#6969dd #e0e0e0',
    scrollbarWidth: 'thin',
    background: 'rgba(128, 128, 128, 0.9)'
}

const mobile_story_container = {
    maxWidth: '100%',
   
    maxHeight: '20rem',
    color: 'white',
    overflowY: 'scroll',
    scrollbarGutter: 'stable',
    scrollbarColor: '#6969dd #e0e0e0',
    scrollbarWidth: 'thin',
    background: 'rgba(128, 128, 128, 0.9)',
    marginTop: '2%',
    marginBottom: '2%',
}

const container = {
    display: 'flex',
    marginLeft: '5%',
    marginRight: '5%',
    paddingBottom: '5%',
    width: '90%',
    height: 'fit-content',
    maxHeight: '70vh',
}

const mobile_container = {
    margin: 'auto',
    paddingBottom: '5%',
    width: '90%',
    height: 'fit-content',
    maxHeight: '70vh',
}

const box = {
    display: 'flex',
    marginLeft: '0.5%',
    marginRight: '0.5%',
}

const mobile_box = {
    marginLeft: '0.5%',
    marginRight: '0.5%',
    
}

const story = {
    marginLeft: '3%',
    marginTop: '2%',
    marginRight: '3%',
    marginBottom: '2%',
    lineHeight: '1.5',
    fontWeight: 'bold',
}

