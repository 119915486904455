import 'bootstrap/dist/css/bootstrap.css';
import React, {Suspense} from 'react';
import {useGLTF, OrbitControls} from "@react-three/drei";
import { Canvas, useFrame} from "@react-three/fiber";
import {useRef} from 'react';

function Model({...props}){
    const group = useRef();
    const { nodes, materials } = useGLTF('/lantern_model/scene.gltf');

    useFrame((state) => {
      group.current.rotation.y += 0.003;
      console.log(state.camera);
    });

    return (
        <group ref={group} {...props} dispose={null}>
            <group position={[0, -110, 0]} rotation={[-3.14, -2.2, 0]}>
                <mesh geometry={nodes.Object_2.geometry} material={materials.Dis_c1_B} />
                <mesh geometry={nodes.Object_3.geometry} material={materials.Disc2B} />
                <mesh geometry={nodes.Object_4.geometry} material={materials.Llanterna_m_gica_LPLlanterna_m_gica_LP_Material_u1_v1} />
                <mesh geometry={nodes.Object_5.geometry} material={materials.Llanterna_m_gica_LPLlanterna_m_gica_LP_Material_u1_v1} />
                <mesh geometry={nodes.Object_6.geometry} material={materials.Llanterna_m_gica_LPLlanterna_m_gica_LP_Material_u1_v1} />
                <mesh geometry={nodes.Object_7.geometry} material={materials.Llanterna_m_gica_LPLlanterna_m_gica_LP_Material_u1_v1} />
                <mesh geometry={nodes.Object_8.geometry} material={materials.Ases} />
                <mesh geometry={nodes.Object_9.geometry} material={materials.Dis_c1} />
                <mesh geometry={nodes.Object_10.geometry} material={materials.Disc2} />
            </group>
        </group>
    )
}

function Loading() {
    return (
      <mesh visible position={[0, 0, 0]} rotation={[0, 0, 0]}>
        <sphereGeometry attach="geometry" args={[1, 16, 16]} />
        <meshStandardMaterial
          attach="material"
          color="white"
          transparent
          opacity={0.6}
          roughness={1}
          metalness={0}
        />
      </mesh>
    );
  }



function Lantern() {
    return (
        <div style = {{height: "60vh", backgroundColor: 'grey'}}>
            <Canvas camera={{position: [0,120, 220], target: [0,0,0], rotation: [-1, -1.3, -0.96]}}>
                    <OrbitControls
                      enablePan = {false}
                    
                      />
                    <ambientLight/>
                    
                    <Suspense fallback={<Loading />}>
                        <Model />
                    </Suspense>
            </Canvas>
        </div>  
    )
}

export default Lantern;