function AboutPage() {
    return (
        <>
        <h1 className="title">About</h1>

        <div style={container}>
                    
                    <div style={box}>
                        
                        <div style={story_container}>
                            <p className="description">
                                Magic Lanterns are one of the earliest forms of projection technology. From the late 19th century and throughout the early to mid 20th century, people used these lanterns for a variety of presentations: from children’s stories, to lectures, to sharing holiday photos. Many of the slide images used for various presentations still survive today. In this project, Illuminating St Andrews, we have attempted to recreate a magic lantern show using lantern slides found within the St Andrews Special Collections and the Cupar Archives. Our show curates a selection of slides from various collection sets to tell local histories of the St Andrews area. We aim to spur new interest in this older form of technology by providing the chance to experience an original lantern and slides, while also providing our own interpretation and imagination to the stories held within the images.  
                            </p>
                        </div>
                    </div>
                
                    
                </div>
        </>
    )


}

export default AboutPage;

const story_container = {
    maxWidth: '80%',
    height: 'fit-content',
    maxHeight: '100%',
    margin: 'auto',
    color: 'white',
    overflowY: 'scroll',
    scrollbarGutter: 'stable',
    scrollbarColor: '#6969dd #e0e0e0',
    scrollbarWidth: 'thin',
    background: 'rgba(128, 128, 128, 0.9)',
}



const container = {
    display: 'flex',
    marginLeft: '5%',
    marginRight: '5%',
    paddingBottom: '5%',
    width: '90%',
    height: 'fit-content',
    maxHeight: '70vh',
}



const box = {
    display: 'flex',
    marginLeft: '0.5%',
    marginRight: '0.5%',
}

