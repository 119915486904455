import React from 'react';
import { useSpring, animated } from 'react-spring';

import Tag from '../components/Tag';

function Slide(props) {
    
    const style = useSpring({
        from: {opacity: 0},
        to: {opacity: 1},
        config: {duration: 800}
    });
    
    return (
        <>
            <animated.div style = {style}>
                    <a style={{textDecoration: 'none'}} href = {props.route}>
                        <img style={img} src={props.image} alt=""/>   
                        <Tag text={props.text}></Tag>
                    </a> 
            </animated.div> 
        </>
    )

}

const img = {
    maxWidth: '100%',
    maxHeight: '100%',
    marginBottom: '1%'
}

export default Slide;