
import '../css/ShowPage.css'

import slide1 from '../assets/dundee_slide_rs.png'
import slide2 from '../assets/beekeeping_slide_rs.png'
import slide3 from '../assets/fantasmagoria_slide_rs.png'

import React, {useState, useEffect} from 'react';

import Slide from '../components/Slide'

function StoriesPage() {


    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() =>{
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    },[]);
      
    const isMobile = width <= 600;

    
    return (
        <>
            {isMobile && (<h2 className="title">Stories</h2>)}
            <div className="stories_page">
                    <div>
                         <Slide text='Dundee: a Century of Planning and Care' route="/story/1" image={slide1}></Slide>
                    </div>
                    <div>
                        <Slide text='Beekeeping' route="/story/2" image={slide2}></Slide>
                    </div>
    
                    <div>
                        <Slide text="St Andrews' Ghost Stories" route="/story/3" image={slide3}></Slide>
                    </div>
             </div>  
        </>
            
    )  
}

export default StoriesPage;